:root {
    --background-000: #000000;
    --background-005: #090a0d;
    --background-010: #121319;
    --background-015: #1b1d25;
    --background-020: #2e313d;
    --background-030: #424755;
    --background-040: #585d6e;
    --background-050: #6f7486;
    --background-060: #878c9e;
    --background-070: #a1a5b6;
    --background-080: #bcc0cf;
    --background-085: #d8dbe7;
    --background-090: #e6e9f3;
    --background-095: #f5f7ff;
    --background-100: #ffffff;

    --foreground-000: #ffffff;
    --foreground-005: #f5f7ff;
    --foreground-010: #e6e9f3;
    --foreground-015: #d8dbe7;
    --foreground-020: #bcc0cf;
    --foreground-030: #a1a5b6;
    --foreground-040: #878c9e;
    --foreground-050: #6f7486;
    --foreground-060: #585d6e;
    --foreground-070: #424755;
    --foreground-080: #2e313d;
    --foreground-085: #1b1d25;
    --foreground-090: #121319;
    --foreground-095: #090a0d;
    --foreground-100: #000000;
}