@font-face {
    font-family: 'TheJamsil5Bold';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2302_01@1.0/TheJamsil5Bold.woff2') format('woff2');
    font-style: normal;
}

@font-face {
    font-family: 'SUITE-Regular';
    src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2304-2@1.0/SUITE-Regular.woff2') format('woff2');
    font-style: normal;
}

* {
    font-family: 'SUITE-Regular', serif;
    font-weight: 600;
}

h1 {
    font-family: 'TheJamsil5Bold', serif;
    font-weight: 800;
    font-size: 25px;
}